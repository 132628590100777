import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUserData } from "./interface";
import { rearrangeUserObj } from "../../utils/helper/rearrangeUserObj";

interface IState {
  email: string | null;
  userViewData: IUserData;
}

const userInitial = {
  email: null,
  uid: null
}

const initialState: IState = {
  email: null,
  userViewData: userInitial,
}

const userViewSlice = createSlice({
  name: 'userView',
  initialState,
  reducers: {
    setEmail: (state: any, action: PayloadAction<any>) => {
      state.email = action.payload;
    },
    setUserViewData: (state: any, action: PayloadAction<any>) => {
      state.userViewData = rearrangeUserObj(action.payload);
    },
  }
})

export const { setEmail, setUserViewData } = userViewSlice.actions;
export default userViewSlice.reducer;