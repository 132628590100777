import * as Yup from 'yup';

const special_char_regex = /[,.~`!@#$%^&*()_\-+=|{[\]}:;"'<>\\\/?]$/

export const yupValidation: any = {
  'age': Yup.number().typeError('Must be a number').min(10, 'Minimum age is 10').max(100, 'Maximum age is 100').notRequired(),
  'city': Yup.string().required('City is required'),
  'country': Yup.string().required('Country is required'),
  'email': Yup.string().email('Invalid email').required('Email is required'),
  'job_role': Yup.string().required('Job role is required'),
  'name': Yup.string().required('Name is required'),
  'phone_number': Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Phone number is not valid').notRequired(),
  'about': Yup.string().required().min(160, 'Minimum length should be 160').max(300, 'Maximum length should be 300'),
  'soft_skills': Yup.string().test('no-trailing-special-chars', 'Cannot end with special characters', (value: any) => {
    return !special_char_regex.test(value);
  }),
  'key_skills': Yup.string().test('no-trailing-special-chars', 'Cannot end with special characters', (value: any) => {
    return !special_char_regex.test(value)
  }),
  'languages': Yup.string().test('no-trailing-special-chars', 'Cannot end with special characters', (value: any) => {
    return !special_char_regex.test(value)
  }),
  'other_skills': Yup.string().test('no-trailing-special-chars', 'Cannot end with special characters', (value: any) => {
    return !special_char_regex.test(value)
  }),
  'important_links': Yup.string().test('no-trailing-special-chars', 'Cannot end with special characters', (value: any) => {
    return !special_char_regex.test(value)
  }),
  'achievements': {
    title: Yup.string().required('Title is required'),
    description: Yup.string().notRequired(),
    media: Yup.array().of(Yup.string()).notRequired()
  },
  'certifications': {
    title: Yup.string().required('Title is required'),
    description: Yup.string().notRequired(),
    media: Yup.array().of(Yup.string()).notRequired()
  },
  'education': {
    degree: Yup.string().required('Degree is required'),
    school: Yup.string().required('School is required'),
    start_date: Yup.string().matches(/^(0[1-9]|1[0-2])\/(19|20)\d{2}$/, 'Date must be in MM/YYYY format').notRequired(),
    end_date: Yup.string().matches(/^(0[1-9]|1[0-2])\/(19|20)\d{2}$/, 'Date must be in MM/YYYY format, leave blank for present').notRequired(),
    description: Yup.string().notRequired(),
    media: Yup.array().of(Yup.string()).notRequired()
  },
  'projects': {
    title: Yup.string().required('Title is required'),
    start_date: Yup.string().matches(/^(0[1-9]|1[0-2])\/(19|20)\d{2}$/, 'Date must be in MM/YYYY format').notRequired(),
    end_date: Yup.string().matches(/^(0[1-9]|1[0-2])\/(19|20)\d{2}$/, 'Date must be in MM/YYYY format, leave blank for present').notRequired(),
    description: Yup.string().notRequired(),
    media: Yup.array().of(Yup.string()).notRequired()
  },
  'work_experience': {
    role: Yup.string().required('Role is required'),
    company: Yup.string().required('Company is required'),
    start_date: Yup.string().matches(/^(0[1-9]|1[0-2])\/(19|20)\d{2}$/, 'Date must be in MM/YYYY format').notRequired(),
    end_date: Yup.string().matches(/^(0[1-9]|1[0-2])\/(19|20)\d{2}$/, 'Date must be in MM/YYYY format, leave blank for present').notRequired(),
    description: Yup.string().notRequired(),
    media: Yup.array().of(Yup.string()).notRequired()
  },
  bg: Yup.string().required('Background is required'),
}

