
import { MultiInputFormsFields } from '../utils/helper/constants';
import MultiInputForms from './MultiInputForms';
import SingleInputForms from './SingleInputForms';
import { IAddSectionForm } from './interface';



const AddSectionForm = ({ SelectedField, setOpen }: IAddSectionForm) => {
  return (
    MultiInputFormsFields.includes(SelectedField) ?
      <MultiInputForms SelectedField={SelectedField} setOpen={setOpen} /> :
      <SingleInputForms SelectedField={SelectedField} setOpen={setOpen} />
  )
}

export default AddSectionForm;