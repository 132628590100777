import { PiTextColumnsBold } from "react-icons/pi";
import { TbTools } from "react-icons/tb";
import { MdPostAdd } from "react-icons/md";
import { LuLanguages } from "react-icons/lu";
import { BsPersonLinesFill } from "react-icons/bs";
import { GrLink } from "react-icons/gr";
import { IoRibbonOutline } from "react-icons/io5";
import { TbCertificate } from "react-icons/tb";
import { BiBriefcase } from "react-icons/bi";
import { TbBooks } from "react-icons/tb";
import { PiCompassToolBold } from "react-icons/pi";

export const icons: { [key: string]: React.ReactElement } = {
  'about': <PiTextColumnsBold size={18} />,
  'key_skills': <TbTools size={18} />,
  'other_skills': <MdPostAdd size={18} />,
  'languages': <LuLanguages size={18} />,
  'soft_skills': <BsPersonLinesFill size={18} />,
  'important_links': <GrLink size={16} />,
  'achievements': < IoRibbonOutline size={18} />,
  'certifications': <TbCertificate size={18} />,
  'work_experience': <BiBriefcase size={18} />,
  'education': <TbBooks size={18} />,
  'projects': <PiCompassToolBold size={18} />
}