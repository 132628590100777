
import { IAddSectionForm } from './interface';
import { useDispatch, useSelector } from 'react-redux';
import { setUserAuth } from '../redux/slices/userAuthSlice';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { db } from '../firebase-config';
import { collection, getDocs, query, updateDoc, where } from 'firebase/firestore';
import FormComponent from '../core/FormComponent';
import { IFormComponent } from '../core/interface';
import { FieldType } from '../utils/helper/FieldType';
import { yupValidation } from '../utils/helper/yupValidation';


const SingleInputForms = ({ SelectedField, setOpen }: IAddSectionForm) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.userAuth);
  const [LoaderState, setLoaderState] = useState<boolean>(false);


  const onSubmit = async (values: any) => {
    setLoaderState(true);
    try {
      const q = query(collection(db, "users"), where("email", "==", user.email));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size === 1) {
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, { ...values });
        dispatch(setUserAuth({ ...user, ...values }));
        setOpen(false);
        setLoaderState(false);
      } else {
        toast.error(`Please try again!`);
        setLoaderState(false);
      }
    } catch (error) {
      toast.error(`Please try again!`);
      setLoaderState(false);
    }
  }

  const FormComponentConfig: IFormComponent = {
    fields: {
      [SelectedField]: yupValidation[SelectedField],
    },
    submitButtonText: "Submit",
    onSubmitFn: onSubmit,
    initialValues: {
      [SelectedField]: '',
    },
    fieldType: { [SelectedField]: FieldType[SelectedField] },
    LoaderState: LoaderState
  }


  return (
    <FormComponent {...FormComponentConfig} />
  )
}

export default SingleInputForms