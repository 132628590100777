import { auth } from "../../firebase-config";
import { sendPasswordResetEmail } from "firebase/auth";
import Button from "../../core/Button";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from "react-toastify";
import CustomField from "../../core/CustomField";
import { capitaliseText } from "../../utils/helper/genericFunctions";
import { useState } from "react";

const ForgotPass = () => {
  const [LoaderState, setLoaderState] = useState<boolean>(false);
  const CredentailsSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required')
  });

  const handleAuthenticate = async (values: any) => {
    setLoaderState(true);
    try {
      await sendPasswordResetEmail(auth, values.email);
      toast.success(`We have send a link to ${values.email}`)
      setLoaderState(false);
    } catch (error: any) {
      let err = capitaliseText(error.code.split(/[-/)(]+/).join(' '));
      toast.error(err);
      setLoaderState(false);
    }
  }

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="p-8 w-96 md:w-[500px] lg:w-[700px]">
        <div className="flex flex-row justify-center w-full mb-2">
          <img src={'logo.svg'} className="w-[250px]" alt={'Follio.work logo'} />
        </div>
        <p className="text-base  mb-10 text-center">Share your work with others</p>
        <Formik
          initialValues={{
            email: '',
            password: ''
          }}
          validationSchema={CredentailsSchema}
          onSubmit={(values: any) => handleAuthenticate(values)}
        >
          {({ errors, touched, values }: any) => (
            <Form>
              <div className="mb-4">
                <CustomField
                  name="email"
                  placeholder="Email"
                  isError={errors.email}
                />
                {errors.email && touched.email &&
                  <p className="text-orange-700 text-sm mt-2 font-semibold">{errors.email}</p>
                }
              </div>
              <Button
                disabled={errors.email || !values.email || LoaderState}
                variant="primary"
                type="submit"
                LoaderState={LoaderState}
              >
                Send Link
              </Button>

            </Form>
          )}
        </Formik>
        <p className="text-sm font-medium mt-10 text-center text-gray-500"><a href={"/login"} className="underline cursor-pointer">Go Back</a></p>
      </div>
    </div>
  )
}

export default ForgotPass;