import { useEffect, useState } from "react";
import Card from "../core/Card"
import { IUser } from "./interface";
import { LuMapPin } from "react-icons/lu";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { BiMobileAlt } from "react-icons/bi";
import { RiCake3Line } from "react-icons/ri";
import Editable from "../core/Editable";
import { GetInfoTypeKey } from "../utils/helper/genericFunctions";
import { nonEditableFields } from "../utils/helper/constants";
import { GrGlobe } from "react-icons/gr";


const UserSecondary = ({ user, isEditable = true }: IUser) => {

  const [ContactInfo, setContactInfo] = useState<any[]>([]);

  useEffect(() => {
    let data = [];
    user?.email && data.push({ icon: <MdOutlineAlternateEmail size={14} />, data: user?.email, type: 'email' });
    user?.age && data.push({ icon: <RiCake3Line size={15} />, data: user?.age, type: 'age' });
    user?.city && data.push({ icon: <LuMapPin size={14} />, data: user?.city, type: 'city' });
    user?.country && data.push({ icon: <GrGlobe size={13} />, data: user?.country, type: 'country' });
    user?.phone_number && data.push({ icon: <BiMobileAlt size={15} />, data: user?.phone_number, type: 'phone_number' });
    setContactInfo(data);
  }, [user])

  return (
    <Card bgColor="#ffffff">
      <div className='flex flex-row flex-wrap gap-y-2 gap-x-4 w-80 sm:w-[580px]'>
        {ContactInfo.map((item: any) => {
          return (
            <div key={item.data} className='flex flex-row items-center gap-1 text-[0.85em] font-medium'>
              {item.icon}
              <Editable
                isEditable={!nonEditableFields.includes(item.type) && isEditable}
                className="text-sm"
                text={item.data}
                infoType={GetInfoTypeKey(user, item.data)}
              />
            </div>
          )
        })}
      </div>
    </Card>
  )
}

export default UserSecondary