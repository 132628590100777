import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../../firebase-config";
import { setUserViewData } from "../../redux/slices/userViewSlice";
import Loader from "../../core/Loader";
import UserPrimary from "../../components/UserPrimary";
import UserSecondary from "../../components/UserSecondary";
import { SlSocialDropbox } from "react-icons/sl";
import UserDetailsTypeA from "../../components/UserDetailsTypeA";
import LinksComponent from "../../components/LinksComponent";
import { BsPostcardHeartFill } from "react-icons/bs";
import UserDetailsTypeB from "../../components/UserDetailsTypeB";

const ViewOnly = () => {
  const dispatch = useDispatch();
  const { email, userViewData } = useSelector((state: any) => state.userView);
  const CAN_EDIT = false;
  const [EmailNotAvailable, setEmailNotAvailable] = useState<boolean | null>(null);

  useEffect(() => {
    if (email) {
      getAllData();
    }
  }, [email])

  const getAllData = async () => {
    setEmailNotAvailable(null);
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        dispatch(setUserViewData(data));
        if (data.name) {
          setEmailNotAvailable(false);
        } else {
          setEmailNotAvailable(true);
        }
      })
    } else {
      setEmailNotAvailable(true);
    }
  }

  if (!userViewData?.email || EmailNotAvailable === null) {
    return <Loader />
  }


  if (EmailNotAvailable) {
    return (
      <div className='flex flex-col gap-2 justify-center items-center h-full'>
        <SlSocialDropbox size={30} color="gray" />
        <p className="font-semibold text-gray-500">Portfolio not found!</p>
      </div>
    )
  }

  return (
    <div className='flex flex-col items-center'>
      <UserPrimary user={userViewData} isEditable={CAN_EDIT} />
      <UserSecondary user={userViewData} isEditable={CAN_EDIT} />
      <UserDetailsTypeA user={userViewData} isEditable={CAN_EDIT} />
      <UserDetailsTypeB user={userViewData} isEditable={CAN_EDIT} />
      <LinksComponent user={userViewData} isEditable={CAN_EDIT} />
      <div className="text-gray-400 text-sm mt-8 font-medium flex flex-row items-center gap-x-1 justify-center bg-gray-900 rounded-md p-2">
        <p> Make your portfolio with</p>
        <a href="/create-account" className="text-primary-500 font-semibold mr-1 underline">follio.work</a>
        <BsPostcardHeartFill size={20} />
      </div>
    </div>
  )
}

export default ViewOnly;