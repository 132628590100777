// src/redux/store.js
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer, persistStore } from 'redux-persist';
import userAuthSlice from "./slices/userAuthSlice";
import userViewSlice from "./slices/userViewSlice";

const persistConfig = {
  key: 'root',
  storage: storageSession,
}

const reducers = combineReducers({
  userAuth: userAuthSlice,
  userView: userViewSlice
})

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export const persistor = persistStore(store)
