import React from 'react'
import Modal from './Modal';
import Button from './Button';

interface IAlert {
  AlertModal: boolean;
  setAlertModal: React.Dispatch<React.SetStateAction<boolean>>;
  ActionFn: (...args: any) => any;
  AlertTitle: string;
}

const Alert = ({ AlertModal, setAlertModal, ActionFn, AlertTitle }: IAlert) => {
  return (
    <Modal
      Open={AlertModal}
      setOpen={setAlertModal}
      title={`Alert`}
    >
      <p className='text-center'>{AlertTitle}</p>
      <div className="flex flex-row gap-x-4 my-4">
        <Button
          disabled={false}
          variant="secondary"
          type="submit"
          LoaderState={false}
          onClick={() => {
            setAlertModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={false}
          variant="primary"
          type="submit"
          LoaderState={false}
          onClick={() => {
            ActionFn()
            setAlertModal(false);
          }}
        >
          Delete
        </Button>
      </div>
    </Modal>
  )
}

export default Alert;