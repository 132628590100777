import Button from "../core/Button";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { IFormComponent } from "./interface";
import CustomField from "./CustomField";
import { capitaliseText } from "../utils/helper/genericFunctions";


const FormComponent = ({
  fields,
  submitButtonText,
  onSubmitFn,
  initialValues,
  fieldType,
  LoaderState
}: IFormComponent) => {
  const schema = Yup.object().shape(fields);

  return (
    <div className="flex items-center justify-center">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values: any) => {
          onSubmitFn(values)
        }}
      >
        {({ isValid, dirty, errors, touched }: any) => (
          <Form className="w-full">
            {Object.keys(initialValues).map((item: any, index: any) => {
              let placeholder = capitaliseText(item) || '';
              return (
                <div className="mb-4" key={index}>
                  <CustomField
                    name={item}
                    placeholder={placeholder}
                    isError={errors[item]}
                    fieldType={fieldType?.[item]}
                  />
                  {errors[item] &&
                    <p className="text-orange-700 text-sm mt-2 font-semibold">{errors[item]}</p>
                  }
                </div>
              )
            })}
            <Button
              disabled={!(isValid && dirty) || LoaderState}
              variant="primary"
              type="submit"
              LoaderState={LoaderState}
            >
              {submitButtonText}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default FormComponent;