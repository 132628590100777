import profile_pic from '../assets/profile.svg';
import Card from '../core/Card';
import { MdOutlineFileUpload } from "react-icons/md";
import { IUser } from './interface';
import { useEffect } from 'react';
import useProfilePictureUpload from '../utils/hooks/UseProfilePictureUpload';
import Editable from '../core/Editable';
import { GetInfoTypeKey } from '../utils/helper/genericFunctions';

const UserPrimary = ({ user, isEditable = true }: IUser) => {

  const { profilePicture, setProfilePicture, handleProfilePicInput } = useProfilePictureUpload({
    email: user?.email,
    profile_pic: user?.profile_pic
  })

  useEffect(() => {
    setProfilePicture(null);
    if (user?.profile_pic) {
      setProfilePicture(user?.profile_pic);
    }
  }, [user])

  return (
    <Card bgColor="#ffffff">
      <div className='flex flex-col w-80 sm:w-[580px]'>
        <div className='flex flex-row items-center gap-x-2 sm:gap-x-4'>
          <div className='rounded-2xl border-[1px] border-gray-300 relative group'>
            <img src={profilePicture || profile_pic} alt={user.name} className='profile_bg rounded-2xl w-20 h-20 object-cover' />
            {isEditable && <label htmlFor="hiddenFileInput" className='hidden cursor-pointer group-hover:flex rounded-2xl absolute top-0 right-0 left-0 bottom-0 bg-black bg-opacity-70 flex-row justify-center items-center'>
              <MdOutlineFileUpload size={30} color={'#ffffff'} />
              <input
                type="file"
                id="hiddenFileInput"
                accept="image/*"
                onChange={handleProfilePicInput}
                className='rounded-2xl w-14 xs:w-16 sm:min-w-20 h-14 xs:h-16 sm:min-h-20 z-50 absolute invisible'
                multiple={false}
              />
            </label>}
          </div>
          <div className='flex flex-col justify-evenly gap-y-1 items-start'>
            <p className="text-xl sm:text-2xl font-bold text-black">{user.name}</p>
            <div className='bg-primary-100 border-[1px] border-primary-500 px-2 py-1 mb-[2px] rounded-md'>
              <Editable
                isEditable={isEditable}
                className="text-sm font-medium text-black editable"
                text={user.job_role}
                infoType={GetInfoTypeKey(user, user.job_role)}
              />
            </div>
            
          </div>
        </div>
      </div>
    </Card>
  )
}

export default UserPrimary