import { MutatingDots } from 'react-loader-spinner';

const Loader = () => {
  return (
    <div className='Loader_container'>
      <MutatingDots
        visible={true}
        height="100"
        width="100"
        color="#0B43BD"
        secondaryColor="#0B43BD"
        radius="12.5"
        ariaLabel="mutating-dots-loading"
      />
    </div>
  )
}

export default Loader;