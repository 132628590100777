import { useState } from 'react';
import { IEditable, IFormComponent } from './interface';
import Modal from './Modal';
import { GetIconsLink, capitaliseText } from '../utils/helper/genericFunctions';
import FormComponent from './FormComponent';
import { collection, deleteField, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { db } from '../firebase-config';
import { setUserAuth } from '../redux/slices/userAuthSlice';
import { toast } from 'react-toastify';
import { yupValidation } from '../utils/helper/yupValidation';
import { FieldType } from '../utils/helper/FieldType';
import { AdditionalFieldTitle } from '../utils/helper/constants';
import { Tooltip } from 'react-tooltip';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const Editable = ({ text, className, infoType, isEditable, showIcon, showText = true }: IEditable) => {
  const { user } = useSelector((state: any) => state.userAuth);
  const dispatch = useDispatch();
  const [Open, setOpen] = useState<boolean>(false);
  const [LoaderState, setLoaderState] = useState<boolean>(false);

  const handleItemClick = () => {
    if (isEditable) {
      setOpen((prev: boolean) => !prev);
    }
  }

  const onSubmit = async (values: any) => {
    setLoaderState(true);
    try {
      const q = query(collection(db, "users"), where("email", "==", user.email));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size === 1) {
        const docRef = querySnapshot.docs[0].ref;
        if (!Object.values(values)?.[0]) { //when field is empty we are deleting the key from firebase.
          await updateDoc(docRef, {
            [Object.keys(values)?.[0]]: deleteField()
          });
          const userDelProp = { ...user };
          delete userDelProp?.[Object.keys(values)?.[0]];
          dispatch(setUserAuth(userDelProp))
        } else {
          await updateDoc(docRef, { ...values });
          dispatch(setUserAuth({ ...user, ...values }));
        }


        setOpen((prev: boolean) => !prev);
        setLoaderState(false);
      } else {
        toast.error(`Please try again!`);
        setLoaderState(false);
      }
    } catch (error) {
      toast.error(`Please try again!`);
      setLoaderState(false);
    }
  }

  const FormComponentConfig: IFormComponent = {
    fields: {
      [infoType]: yupValidation[infoType]
    },
    submitButtonText: "Submit",
    onSubmitFn: onSubmit,
    initialValues: {
      [infoType]: text,
    },
    fieldType: { [infoType]: FieldType[infoType] },
    LoaderState: LoaderState,
  }

  const renderTextBody = () => {
    if (AdditionalFieldTitle?.[infoType] && AdditionalFieldTitle?.[infoType].includes('commas')) {
      return (
        <div className='flex flex-row justify-start items-center gap-2 flex-wrap cursor-pointer' onClick={handleItemClick}>
          {
            text.split(',').map((item, index) => {
              let url = GetIconsLink(item);
              return (
                <div key={index} className='flex flex-row gap-2 items-start'>
                  {showIcon &&
                    <a href={item} target='_blank' data-tooltip-id="tooltip-links"
                      data-tooltip-content={item}
                      className='mr-2 shadow-sm rounded-sm p-1 border-[1px]'>
                      <img src={url} width={30} height={30} />
                    </a>}
                  <Tooltip id="tooltip-links" />
                  {showText &&
                    <p className='bg-primary-50 py-2 px-4 rounded-sm'>
                      {capitaliseText(item)}
                    </p>}
                </div>
              )
            })}
        </div >
      )
    } else {
      return (
        !isEditable ?
          <p className={`${isEditable ? 'cursor-pointer' : 'cursor-default'} ${className}`} onClick={handleItemClick}>
            {text}
          </p> :
          <div className={`${isEditable ? 'cursor-pointer' : 'cursor-default'} ${className}`} onClick={handleItemClick}>
            <Markdown remarkPlugins={[remarkGfm]}>{text}</Markdown>
          </div>
      )
    }
  }

  return (
    <>
      {renderTextBody()}
      <Modal
        Open={Open}
        setOpen={setOpen}
        title={`Change ${capitaliseText(infoType)}`}
      >
        {AdditionalFieldTitle[infoType] &&
          <p className="text-sm text-gray-500 font-semibold mt-[2px] mb-4">({AdditionalFieldTitle[infoType]})</p>
        }
        <FormComponent {...FormComponentConfig} />
      </Modal>
    </>
  )
}

export default Editable;