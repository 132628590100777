import { useState } from "react";
import { Field } from 'formik';
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";


const PasswordVisibilityToggle = ({ field, form }: any) => {
  const [showHidePassword, changeShowHidePassword] = useState(false);
  const hasError = form.touched[field.name] && form.errors[field.name];

  return (
    <div className="relative">
      <div className="cursor-pointer absolute right-2 h-full flex flex-row items-center"
        onClick={() => changeShowHidePassword(!showHidePassword)}>
        {showHidePassword ?
          <IoEyeOffOutline size={20} className="text-slate-600" /> :
          <IoEyeOutline size={20} className="text-slate-600" />
        }
      </div>
      <Field
        type={showHidePassword ? "text" : "password"}
        {...field}
        className={`w-full pr-8 rounded-lg border-[1px] border-gray-200 shadow-md py-3 px-3 text-gray-900 leading-tight focus:outline-1 ${hasError ? 'outline-orange-500' : 'outline-black'}`}
        placeholder="Password"
      />
    </div>
  );
};

export default PasswordVisibilityToggle;
