import { Field } from 'formik';
import { ICustomField } from './interface';
import PasswordVisibilityToggle from './PasswordVisibilityToggle';

const CustomField = ({
  name,
  placeholder,
  isError,
  fieldType = '',
}: ICustomField) => {

  const props: any = {
    textarea: { rows: 6 },
  }

  if (fieldType === 'password') {
    return (
      <Field
        name={name}
        type="password"
        placeholder={placeholder}
        component={PasswordVisibilityToggle}
      />
    )
  } else {
    return (
      <Field
        as={fieldType}
        className={`w-full resize-none rounded-lg border-[1px] leading-relaxed border-gray-200 shadow-md py-3 px-3 text-gray-900 focus:outline-1 ${isError ? 'outline-orange-500' : 'outline-black'}`}
        name={name}
        placeholder={placeholder}
        {...props[fieldType]}
      />
    )
  }

}

export default CustomField;