import { IUser } from "../../components/interface";

export const rearrangeUserObj = (originalObject: any) => {
  const positionPriority = [
    "name",
    "job_role",
    "bg",
    "email",
    "phone_number",
    "city",
    "country",
    "age",
    "profile_pic",
    "about",
    "key_skills",
    "soft_skills",
    "other_skills",
    "languages",
    "important_links",
    "work_experience",
    "projects",
    "education",
    "achievements",
    "certifications"
  ];

  const rearrangedObject: IUser | any = {}

  positionPriority.forEach((key: any) => {
    if (originalObject.hasOwnProperty(key)) {
      rearrangedObject[key] = originalObject[key]
    }
  });

  return rearrangedObject;
};

