import { useState, ChangeEvent, useEffect } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { db } from '../../firebase-config';
import imageCompression from 'browser-image-compression';


interface UseProfilePictureUploadOptions {
  email: string;
  profile_pic?: string;
  onSuccess?: () => void;
  onError?: () => void;
}

const useProfilePictureUpload = (options: UseProfilePictureUploadOptions) => {
  const { email, profile_pic, onSuccess, onError } = options;
  const [profilePicture, setProfilePicture] = useState<string | null>(profile_pic || null);

  const handleProfilePicInput = async (event: ChangeEvent<HTMLInputElement>) => {
    let oldImage = profilePicture;
    if (event.target.files) {
      const file = event.target.files?.[0];
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      }
      const compressedFile = await imageCompression(file, options);
      const metadata = {
        contentType: compressedFile.type,
      };
      const storage = getStorage();
      const profilePicRef = ref(storage, 'user_profile/' + compressedFile.name);
      const uploadTask = uploadBytesResumable(profilePicRef, compressedFile, metadata);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Handle progress if needed
        },
        (error) => {
          toast.error('Profile picture upload failed, please try again!');
          onError && onError();
        },
        () => {
          toast.success('Profile picture uploaded successfully');

          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            if (oldImage) {
              const deleteRef = ref(storage, oldImage);
              deleteObject(deleteRef);
            }

            const q = query(collection(db, 'users'), where('email', '==', email));
            const querySnapshot = await getDocs(q);
            if (querySnapshot.size === 1) {
              const docRef = querySnapshot.docs[0].ref;
              await updateDoc(docRef, { profile_pic: downloadURL });
              setProfilePicture(downloadURL);
              onSuccess && onSuccess();
            }
          });
        }
      );
    }

    // Reset the file input
    if (event.target) {
      event.target.value = '';
    }
  };

  useEffect(() => {
    // Set initial profile picture on component mount
    setProfilePicture(profile_pic || null);
  }, [profile_pic]);

  return {
    profilePicture,
    setProfilePicture,
    handleProfilePicInput,
  };
};

export default useProfilePictureUpload;
