import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUserData } from "./interface";
import { rearrangeUserObj } from "../../utils/helper/rearrangeUserObj";

interface IState {
  user: IUserData;
}

const userInitial = {
  email: null,
  uid: null
}

const initialState: IState = {
  user: userInitial,
}

const userAuthSlice = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    setUserAuth: (state: any, action: PayloadAction<any>) => {
      state.user = rearrangeUserObj(action.payload);
    },
    clearUserAuth: (state: any) => {
      state.user = userInitial;
    }
  }
})

export const { setUserAuth, clearUserAuth } = userAuthSlice.actions;
export default userAuthSlice.reducer;