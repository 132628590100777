import { Carousel } from 'react-responsive-carousel';
import { ICarouselModal } from '../components/interface';
import Modal from './Modal';
import { useEffect, useState } from 'react';

const CarouselModal = ({ isOpen, onClose, media, title }: ICarouselModal) => {
  const [Open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen])



  useEffect(() => {
    if (!Open) {
      onClose();
    }
  }, [Open])

  if (!media.length) return null;

  return (
    <Modal Open={Open} setOpen={setOpen} title={title}>
      <Carousel key={title} showArrows={true} showThumbs={true} infiniteLoop={true}>
        {media.map((item, index) => {
          return (
            <div key={index}>
              <img src={item} alt="media" className=' max-h-80 object-scale-down' />
            </div>
          )
        })}
      </Carousel>
    </Modal>
  )
}

export default CarouselModal