import { signOut } from 'firebase/auth';
import { auth, db } from '../firebase-config';
import { clearUserAuth, setUserAuth } from '../redux/slices/userAuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import { MdAdd } from "react-icons/md";
import { BiLogOut } from "react-icons/bi";
import { useState } from 'react';
import { MdOutlineSettings } from "react-icons/md";
import { TbExternalLink } from "react-icons/tb";
import Modal from '../core/Modal';
import { RiImageAddLine } from 'react-icons/ri';
import { Field, Form, Formik } from 'formik';
import Button from '../core/Button';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { collection, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { FaWindowClose } from 'react-icons/fa';
import { toast } from 'react-toastify';


const FloatMenu = () => {
  const { user } = useSelector((state: any) => state.userAuth);
  const [Settings, setSettings] = useState<boolean>(false);
  const [BGImage, setBGImage] = useState(null);

  const dispatch = useDispatch();
  const [ExpandMenu, setExpandMenu] = useState<boolean>(false);
  const [LoaderState, setLoaderState] = useState<boolean>(false);

  const logout = async () => {
    await signOut(auth).then(() => {
      dispatch(clearUserAuth());
    }).catch((err) => {
      console.error(err);
    })
  }

  const toggleMenu = () => {
    setExpandMenu((prev: boolean) => !prev);
  }

  const RemoveBG = () => {
    setLoaderState(true);
    const storage = getStorage();
    const deleteRef = ref(storage, user.bg);
    deleteObject(deleteRef).then(() => {
      const q = query(collection(db, 'users'), where('email', '==', user.email));
      getDocs(q).then(async (querySnapshot) => {
        if (querySnapshot.size === 1) {
          const docRef = querySnapshot.docs[0].ref;
          await updateDoc(docRef, { bg: '' });
          dispatch(setUserAuth({ ...user, bg: '' }));
          toast.success('Background removed successfully');
          setLoaderState(false);
          setSettings(false);
        }
      });
    }).catch((err) => {
      toast.error('Background removal failed, please try again!');
      setLoaderState(false);
      console.error(err);
    })
  }

  return (
    <><div className='absolute top-3 right-3'>
      <div onClick={toggleMenu} className={`h-10 w-10 bg-primary-500 hover:bg-primary-600 shadow-lg rounded-lg flex flex-row justify-center items-center cursor-pointer hover:scale-105`}>
        <MdAdd size={18} color="#fff" />
      </div>
      <div className={`${ExpandMenu ? 'flex' : 'hidden'} flex-col items-center gap-y-2 mt-2`}>
        <div className="h-10 w-10 bg-primary-500 hover:scale-105 hover:bg-primary-600 shadow-md rounded-lg flex flex-row justify-center items-center cursor-pointer" onClick={logout}>
          <BiLogOut size={18} color="#fff" />
        </div>
        <div className="h-10 w-10 bg-primary-500 hover:scale-105 hover:bg-primary-600 shadow-md rounded-lg flex flex-row justify-center items-center cursor-pointer">
          <a href={`/${user?.email}`} target='_blank'><TbExternalLink size={18} color="#fff" /></a>
        </div>
        <div className="h-10 w-10 bg-primary-500 hover:scale-105 hover:bg-primary-600 shadow-md rounded-lg flex flex-row justify-center items-center cursor-pointer">
          <MdOutlineSettings size={18} color="#fff" onClick={() => { setSettings(true); }} />
        </div>
      </div>
    </div><Modal
      title="Settings"
      Open={Settings}
      setOpen={setSettings}
    >
        <div className="relative flex flex-row items-center">
          <Formik
            enableReinitialize={true}
            initialValues={{
              bg: '',
            }}
            onSubmit={() => {
              if (BGImage) {
                const storage = getStorage();
                setLoaderState(true);
                const bgRef = ref(storage, 'bg/' + user.email);
                const uploadTask = uploadBytesResumable(bgRef, BGImage);

                uploadTask.on(
                  'state_changed',
                  (snapshot) => {
                    // Handle progress if needed
                  },
                  (error) => {
                    toast.error('Background upload failed, please try again!');
                    setLoaderState(false);
                  },
                  () => {
                    toast.success('Background uploaded successfully');
                    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                      const q = query(collection(db, 'users'), where('email', '==', user.email));
                      const querySnapshot = await getDocs(q);
                      if (querySnapshot.size === 1) {
                        const docRef = querySnapshot.docs[0].ref;
                        await updateDoc(docRef, { bg: downloadURL });
                        dispatch(setUserAuth({ ...user, bg: downloadURL }));
                      }
                      setBGImage(null);
                      setSettings(false);
                      setLoaderState(false);
                    });
                  }
                );

              }
            }
            }
          >
            {() => {
              return (
                <Form className='flex flex-row justify-between w-full gap-x-4'>
                  <label htmlFor={'bg'}
                    className='cursor-pointer font-medium text-sm text-gray-400 flex flex-row items-center justify-center gap-x-2 rounded-md p-4 w-96  border-[1px] bg-white shadow-md'>
                    <RiImageAddLine size={20} />
                    Add Background
                    <Field
                      type="file"
                      name={'bg'}
                      accept='image/*'
                      onChange={(event: any) => {
                        setBGImage(event.target.files[0]);
                      }}
                      className='hidden'
                      id={'bg'}
                    />
                  </label>
                  {BGImage || !user?.bg ?
                    <Button
                      variant='primary'
                      type='submit'
                      disabled={BGImage == null || LoaderState}
                      LoaderState={LoaderState}
                    >
                      Upload
                    </Button> :
                    <Button
                      variant='primary'
                      type='submit'
                      disabled={LoaderState}
                      LoaderState={LoaderState}
                      onClick={RemoveBG}
                    >
                      Delete Background
                    </Button>
                  }
                </Form>
              )
            }}
          </Formik>
        </div>
        {BGImage &&
          <div className='relative w-14 border-[1px] my-4'>
            <img src={URL.createObjectURL(BGImage)} alt={'BG Image'}
              className='p-2' />
            <FaWindowClose className='absolute -top-1 -right-1 text-red-500 cursor-pointer'
              onClick={() => setBGImage(null)} />
          </div>}
      </Modal >
    </>
  )
}

export default FloatMenu