import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDkAji8CfdE3_IlqUyAirqiSAkiOgpo4Hw",
  authDomain: "wurk-72f17.firebaseapp.com",
  projectId: "wurk-72f17",
  storageBucket: "wurk-72f17.appspot.com",
  messagingSenderId: "903114376949",
  appId: "1:903114376949:web:2ffd661ffd3caa5de327bc",
  measurementId: "G-YEB2E98JB2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);