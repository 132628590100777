import { useSelector } from 'react-redux';
import UserPrimary from '../../components/UserPrimary';
import UserSecondary from '../../components/UserSecondary';
import AddSection from '../../components/AddSection';
import UserDetailsTypeA from '../../components/UserDetailsTypeA';
import FloatMenu from '../../components/FloatMenu';
import LinksComponent from '../../components/LinksComponent';
import UserDetailsTypeB from '../../components/UserDetailsTypeB';

const Home = () => {
  const { user } = useSelector((state: any) => state.userAuth);

  return (
    <div className='flex flex-col items-center'>
      <UserPrimary user={user} />
      <UserSecondary user={user} />
      <UserDetailsTypeA user={user} />
      <UserDetailsTypeB user={user} />
      <LinksComponent user={user} />
      <AddSection />
      <FloatMenu />
    </div>
  )
}

export default Home;