import { useEffect, useState } from 'react';
import { IButton } from './interface';
import { RotatingLines } from 'react-loader-spinner';

const Button = ({
  onClick,
  disabled = false,
  variant,
  type,
  children,
  LoaderState
}: IButton) => {

  const [ClassStyle, setClassStyle] = useState<string>('');

  useEffect(() => {
    switch (variant) {
      case 'primary': {
        setClassStyle('w-full rounded-lg bg-primary-500 shadow-md text-white py-3 px-4 font-bold hover:bg-primary-600');
        break;
      };
      case 'secondary': {
        setClassStyle('w-full rounded-lg bg-secondary-800 shadow-md text-white py-3 px-4 font-bold hover:bg-secondary-900');
        break;
      };
      default: setClassStyle('');
    }
  }, [variant])


  if (disabled) {
    return (<button
      className="w-full rounded-lg bg-gray-300 text-gray-400 py-3 px-4 font-bold flex flex-row justify-center items-center gap-2"
      type={type}
    >
      <RotatingLines
        visible={LoaderState}
        strokeWidth="5"
        width={"25"}
        strokeColor={"#6B7280"}
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
      />
      {children}
    </button>
    )
  }

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={ClassStyle}
      type={type}
    >
      {children}
    </button>
  )
}

export default Button