import { auth, db } from "../../firebase-config";
import { createUserWithEmailAndPassword } from "firebase/auth";
import Button from "../../core/Button";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ICredentials } from "./interface";
import { toast } from "react-toastify";
import { collection, addDoc } from "firebase/firestore";
import CustomField from "../../core/CustomField";
import { capitaliseText } from "../../utils/helper/genericFunctions";
import { Navigate } from "react-router-dom";
import { useState } from "react";

const Signup = () => {
  const [LoaderState, setLoaderState] = useState<boolean>(false);

  const CredentailsSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().min(8, 'Password must be 8 characters long').required('password is required')
  });

  const handleSignup = async (values: ICredentials) => {
    setLoaderState(true);
    await createUserWithEmailAndPassword(auth, values.email, values.password).then(async () => {
      await addDoc(collection(db, "users"), {
        email: values.email
      });
      setLoaderState(false);
      return (<Navigate to={`/know-more`} />)
    }).catch((error: any) => {
      let err = capitaliseText(error.code.split(/[-/)(]+/).join(' '));
      toast.error(err);
      setLoaderState(false);
    })
  }

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="p-8 w-96 md:w-[500px] lg:w-[700px]">
        <div className="flex flex-row justify-center w-full mb-2">
          <img src={'logo.svg'} className="w-[250px]" alt={'Follio.work logo'} />
        </div>
        <p className="text-base mb-10 text-center">Share your work with others</p>
        <Formik
          initialValues={{
            email: '',
            password: ''
          }}
          validationSchema={CredentailsSchema}
          onSubmit={(values: any) => handleSignup(values)}
        >
          {({ errors, touched, values }: any) => (
            <Form>
              <div className="mb-4">
                <CustomField
                  name="email"
                  placeholder="Email"
                  isError={errors.email}
                />
                {errors.email && touched.email &&
                  <p className="text-orange-700 text-sm mt-2 font-semibold">{errors.email}</p>
                }
              </div>
              <div className="mb-4">
                <CustomField
                  name="password"
                  fieldType="password"
                  placeholder="Password"
                />
                {errors.password && touched.password &&
                  <p className="text-orange-700 text-sm mt-2  font-semibold">{errors.password}</p>
                }
              </div>
              <Button
                disabled={errors.email || errors.password || !(values.email && values.password) || LoaderState}
                variant="primary"
                type="submit"
                LoaderState={LoaderState}
              >
                Create Account
              </Button>
            </Form>
          )}
        </Formik>
        <p className="text-sm font-medium mt-10 text-center">Already have an account? <a href={"/login"} className="text-sm text-primary-500 underline cursor-pointer">Log in</a></p>
      </div>
    </div>
  )
}

export default Signup;