import { useEffect, useState } from "react";
import { IUser } from "./interface"
import { filterTheseForLinkComponents } from "../utils/helper/constants";
import Card from "../core/Card";
import { icons } from "../utils/helper/icons";
import { GetInfoTypeKey, capitaliseText } from "../utils/helper/genericFunctions";
import Editable from "../core/Editable";

const LinksComponent = ({ user, isEditable = true }: IUser) => {
  const [UserFilter, setUserFilter] = useState<any[]>([]);

  useEffect(() => {
    const data: any = [];
    Object.entries(user).map((item: any[]) => {
      if (filterTheseForLinkComponents.includes(item[0]))
        data.push({
          title: item[0], data: item[1]
        })
    })
    setUserFilter(data);
  }, [user])

  return (
    <div>
    {UserFilter.length > 0 ?
      (UserFilter.map((item, index) => {
        return (
          <Card bgColor="#ffffff" key={index}>
            <div className='flex flex-col w-80 sm:w-[580px]'>
              <div className='flex flex-row items-center gap-2 mb-2'>
                {icons?.[item.title] &&
                  <div className='rounded-full p-2 bg-primary-100'>
                    {icons?.[item.title]}
                  </div>
                }
                <p className='font-bold text-sm'>{capitaliseText(item.title)}</p>
              </div>
              <div className='flex flex-col text-sm mt-2'>
                <Editable
                  isEditable={isEditable}
                  className="text-sm"
                  showIcon={true}
                  showText={false}
                  text={item.data}
                  infoType={GetInfoTypeKey(user, item.data)}
                />
              </div>
            </div>
          </Card>
        )
      })) : null
    }
  </div>
  )
}

export default LinksComponent;