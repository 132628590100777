import Card from "../core/Card";
import { arrangeData, capitaliseText, formatMonthYear } from "../utils/helper/genericFunctions";
import { icons } from "../utils/helper/icons";
import AddSectionForm from './AddSectionForm';
import Modal from '../core/Modal';
import CarouselModal from '../core/CarouselModal';
import { CgPlayListRemove } from "react-icons/cg";
import { SiAddthis } from "react-icons/si";
import { useEffect, useState } from "react";
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { MdEditNote } from "react-icons/md";
import Alert from "../core/Alert";


interface IComponentTypeB {
  item: any;
  deleteItem: (Field: string, item: any) => void;
  isEditable: boolean;
}

const ComponentTypeB = ({ item, deleteItem, isEditable }: IComponentTypeB) => {
  const [ArrangedItem, setArrangedItem] = useState<any>({});
  const [Open, setOpen] = useState<boolean>(false);
  const [SelectedImageContainer, setSelectedImageContainer] = useState<string[]>([]);
  const [DeleteModal, setDeleteModal] = useState(false);
  const [SelectedItem, setSelectedItem] = useState();

  useEffect(() => {
    setArrangedItem(
      arrangeData(item)
    )
  }, [item])

  return (
    <div>
      <Card bgColor="#ffffff">
        <div className='flex flex-col w-80 sm:w-[580px]'>
          <div className='flex fle-row items-center justify-between'>
            <div className='flex flex-row items-center gap-2 mb-2'>
              {icons?.[ArrangedItem.title] &&
                <div className='rounded-full p-2 bg-primary-100'>
                  {icons?.[ArrangedItem.title]}
                </div>}
              <p className='font-bold text-sm'>{capitaliseText(ArrangedItem.title)}</p>
            </div>
            {isEditable &&
              <>
                <SiAddthis size={20} className="cursor-pointer text-primary-500"
                  onClick={() => setOpen(true)} />
                <Modal
                  Open={Open}
                  setOpen={setOpen}
                  title={`Add ${capitaliseText(ArrangedItem.title)} `}
                >
                  <AddSectionForm SelectedField={ArrangedItem.title} setOpen={setOpen} />
                </Modal>
              </>
            }
          </div>
          {ArrangedItem?.data?.map((i: any, index: any) => {
            return (
              <div key={index} className='flex flex-col text-sm gap-y-2 border-b-[1px] py-4 relative'>
                <div className='flex flex-row justify-between gap-2 items-center'>
                  {i?.title &&
                    <p className='font-semibold text-sm flex flex-row gap-x-2'>
                      {`${index + 1}. ` + capitaliseText(i.title)}
                    </p>
                  }
                  {i?.role &&
                    <p className='font-semibold text-sm flex flex-row gap-x-2'>
                      {`${index + 1}. ${capitaliseText(i.role)} (${capitaliseText(i.company)})`}
                    </p>
                  }
                  {i?.degree &&
                    <p className='font-semibold text-sm flex flex-row gap-x-2'>
                      {`${index + 1}. ${capitaliseText(i.degree)} (${capitaliseText(i.school)})`}
                    </p>
                  }
                  {isEditable &&
                    <div className="flex flex-row items-center justify-between gap-x-4">
                      <CgPlayListRemove size={20} onClick={() => {
                        setDeleteModal(true);
                        setSelectedItem(i);
                      }}
                        className="cursor-pointer text-red-500" />
                      {/* <MdEditNote size={22} onClick={() => { }}
                        className="cursor-pointer text-primary-500" /> */}
                    </div>
                  }
                </div>
                {i.start_date &&
                  <p className='text-xs font-semibold'>{`${formatMonthYear(i.start_date)} - ${i.end_date ? formatMonthYear(i.end_date) : 'Present'}`}</p>
                }

                {i.description && <div className='text-sm italic'>
                  <Markdown remarkPlugins={[remarkGfm]}>{i.description}</Markdown>
                </div>
                }
                {i.media &&
                  <div className='flex flex-row gap-2 mt-2 flex-wrap'>
                    {i.media.map((media: any, index: any) => {
                      return (
                        <img key={index} src={media} alt="media"
                          className='w-16 md:w-32 object-contain cursor-pointer border-[1px] rounded-none border-gray-200 shadow-xs'
                          onClick={() => setSelectedImageContainer(i.media)} />
                      );
                    })}
                  </div>}
              </div>
            );
          })}
        </div>
        {isEditable &&
          <Alert
            AlertModal={DeleteModal}
            setAlertModal={setDeleteModal}
            AlertTitle={`Are you sure you want to delete?`}
            ActionFn={() => deleteItem(ArrangedItem.title, SelectedItem)}
          />
        }
      </Card>
      <CarouselModal
        isOpen={SelectedImageContainer.length > 0}
        onClose={() => setSelectedImageContainer([])}
        media={SelectedImageContainer}
        title={capitaliseText(ArrangedItem.title) || ''} />
    </div>
  )
}

export default ComponentTypeB