import { ICard } from "./interface"



const Card = ({ children, bgColor, className, ClickFn }: ICard) => {
  return (
    <div onClick={ClickFn} className={`rounded-md p-4 flex flex-row items-center gap-4 my-1 shadow-lg border-[1px] ${className}`}
      style={{ background: bgColor }}>
      {children}
    </div>
  )
}

export default Card