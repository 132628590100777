import { ReactNode, useEffect, useState } from 'react';
import { db } from '../firebase-config';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from "react-router-dom";
import { setUserAuth } from '../redux/slices/userAuthSlice';
import { collection, getDocs, query, where } from 'firebase/firestore';
import Loader from '../core/Loader';


interface IPrivateRoute {
  children: ReactNode;
}

const PrivateRoute = ({ children }: IPrivateRoute) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => (state.userAuth));
  const url = window.location.pathname.split('/');
  const urlPathMail = url[url?.length - 1];
  const [PageLoad, setPageLoad] = useState<boolean>(false);

  useEffect(() => {
    getAllData();
    setPageLoad(true)
  }, [])

  const getAllData = async () => {
    const q = query(collection(db, "users"), where("email", "==", user.email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      dispatch(setUserAuth({ ...user, ...data }));
      setTimeout(() => {
        setPageLoad(false);
      }, 2000)
    })
  }


  if (!user?.email) {
    return <Navigate to='/login' />
  }


  return (
    <div className="flex flex-row justify-center w-full h-[calc(100dvh)] overflow-x-hidden"
      style={{ backgroundImage: `url(${user?.bg})`, backgroundSize: 'auto', width: '100vw', height: '100vh' }}>
      <div className='px-4 py-4 overflow-auto w-full h-full pb-4'>
        {PageLoad ? <Loader /> : children}
      </div>
    </div>
  )
}

export default PrivateRoute;