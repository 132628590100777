
export const capitaliseText = (text: string) => {
  if (text) {
    let textArr;
    if (text.includes('_')) {
      textArr = text.split('_');
    } else {
      textArr = text.split(' ');
    }
    let capitaliseArr = textArr.map((item: string) => item.charAt(0).toUpperCase() + item.slice(1));
    return capitaliseArr.join(' ')
  }
}

export const GetInfoTypeKey = (user: any, value: string) => {
  return Object.keys(user).filter((f: string) => user[f] === value)?.[0]
}

export const GetIconsLink = (url: string) => {
  let domain;
  // Find & remove protocol (http, ftp, etc.) and get the rest of the URL
  if (url.indexOf('://') > -1) {
    domain = url.split('/')[2];
  } else {
    domain = url.split('/')[0];
  }
  // Find & remove www. or any additional paths or query parameters
  domain = domain.replace('www.', '').split('/')[0];
  return `https://icons.duckduckgo.com/ip3/${domain}.ico`
}

export function generateUniqueId() {
  const currentDate = new Date();

  // Extract various components of the date and time
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');

  // Combine the components to create a unique ID
  const uniqueId = `${year}${month}${day}_${hours}${minutes}${seconds}${milliseconds}`;

  return uniqueId;
}

export const modifyUploadValuesForType = (values: any, SelectedField: string) => {
  switch (SelectedField) {
    case 'education':
      return { degree: values.degree, school: values.school, start_date: values.start_date, end_date: values.end_date, description: values.description }
    case 'projects':
      return { title: values.title, start_date: values.start_date, end_date: values.end_date, description: values.description }
    case 'work_experience':
      return { role: values.role, company: values.company, start_date: values.start_date, end_date: values.end_date, description: values.description }
    case 'achievements':
      return { title: values.title, description: values.description }
    case 'certifications':
      return { title: values.title, description: values.description }
    default:
      return { title: values.title, description: values.description }
  }
}

export const formatMonthYear = (input: string) => {
  const [month, year] = input.split('/');
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const formattedMonth = monthNames[Number(month) - 1];
  const formattedYear = year.padStart(4, '20');
  return `${formattedMonth} ${formattedYear}`;
}


export function arrangeData(obj: any) {
  if (!obj || !obj.data || !Array.isArray(obj.data)) {
    return obj;
  }

  const newData = [...obj.data];

  newData.sort((a: any, b: any) => {
    const endDateA = new Date(
      `${b.end_date || "12/9999"}`.replace(/(\d{2})\/(\d{4})/, '$2-$1')
    ).getTime();
    
    const endDateB = new Date(
      `${a.end_date || "12/9999"}`.replace(/(\d{2})\/(\d{4})/, '$2-$1')
    ).getTime();

    return endDateA - endDateB;
  });

  return { ...obj, data: newData };
}
