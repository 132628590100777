import { useEffect, useState } from 'react';
import { IUser } from './interface';
import { filterTheseFromTypeB } from '../utils/helper/constants';
import { useDispatch } from 'react-redux';
import { setUserAuth } from '../redux/slices/userAuthSlice';
import { collection, deleteField, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../firebase-config';
import { deleteObject, getStorage, ref } from 'firebase/storage';
import ComponentTypeB from './ComponentTypeB';

const UserDetailsTypeB = ({ user, isEditable = true }: IUser) => {
  const dispatch = useDispatch();
  const [UserFilter, setUserFilter] = useState<any[]>([]);

  useEffect(() => {
    const data: any = [];
    Object.entries(user).map((item: any[]) => {
      if (filterTheseFromTypeB.includes(item[0]))
        data.push({
          title: item[0], data: item[1]
        })
    })
    setUserFilter(data);
  }, [user])

  const deleteItem = async (Field: string, item: any) => {
    if (user[Field].length === 1) {
      let RemovedFieldKey = JSON.parse(JSON.stringify(user));
      delete RemovedFieldKey[Field];
      dispatch(setUserAuth(RemovedFieldKey));
      const q = query(collection(db, "users"), where("email", "==", user.email));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size === 1) {
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, { [Field]: deleteField() });
      }
    } else {
      switch (Field) {
        case 'education':
          dispatch(setUserAuth({ ...user, [Field]: user[Field].filter((i: any) => i.degree !== item.degree) }));
          break;
        case 'work_experience':
          dispatch(setUserAuth({ ...user, [Field]: user[Field].filter((i: any) => i.role !== item.role) }));
          break;
        default:
          dispatch(setUserAuth({ ...user, [Field]: user[Field].filter((i: any) => i.title !== item.title) }));
      }
      const q = query(collection(db, "users"), where("email", "==", user.email));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size === 1) {
        const docRef = querySnapshot.docs[0].ref;
        switch (Field) {
          case 'education':
            await updateDoc(docRef, { [Field]: user[Field].filter((i: any) => i.degree !== item.degree) });
            break;
          case 'work_experience':
            await updateDoc(docRef, { [Field]: user[Field].filter((i: any) => i.role !== item.role) });
            break;
          default:
            await updateDoc(docRef, { [Field]: user[Field].filter((i: any) => i.title !== item.title) });
        }
      }
    }

    // delete item.media from storage  media/
    const storage = getStorage();
    item.media.map(async (media: string) => {
      const deleteRef = ref(storage, media);
      deleteObject(deleteRef);
    })
  }

  return (
    <div>
      {UserFilter.length > 0 ?
        (UserFilter.map((item) => {
          return (
            <div key={item.title}>
              <ComponentTypeB
                item={item}
                deleteItem={deleteItem}
                isEditable={isEditable}
              />
            </div>

          )
        })) : null
      }
    </div>
  )
}

export default UserDetailsTypeB