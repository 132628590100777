import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import PrivateRoute from './route/PrivateRoute';
import Signup from './page/Guest/Signup';
import Home from './page/Auth/Home';
import PublicRoute from './route/PublicRoute';
import { useDispatch, useSelector } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import { clearUserAuth, setUserAuth } from './redux/slices/userAuthSlice';
import { auth } from './firebase-config';
import Login from './page/Guest/Login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ForgotPass from "./page/Guest/ForgotPass";
import { useEffect } from "react";
import ViewOnly from "./page/Guest/ViewOnly";
import OnSignupForm from "./page/Auth/OnSignupForm";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

function App() {
  const dispatch = useDispatch();
  const { user: userRedux } = useSelector((state: any) => state.userAuth);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!userRedux?.name) {
        if (user) {
          dispatch(setUserAuth({
            email: user?.email,
            uid: user?.uid
          }));
        } else {
          dispatch(clearUserAuth());
        }
      }
    });
  }, []);

  const routesArr = [
    { path: "/create-account", Element: <Signup />, isAuthRequired: false },
    { path: "/login", Element: <Login />, isAuthRequired: false },
    { path: "/forgot-password", Element: <ForgotPass />, isAuthRequired: false },
    { path: `/:mail`, Element: <ViewOnly />, isAuthRequired: false },
    { path: `/profile/:${userRedux.email}`, Element: <Home />, isAuthRequired: true },
    { path: "/know-more", Element: <OnSignupForm />, isAuthRequired: true },
    { path: `*`, Element: <Navigate to={`/profile/${userRedux.email}`} replace />, isAuthRequired: true },
  ]

  return (
    <BrowserRouter>
      <Routes>
        {/* Public routes */}
        {routesArr.map((item) => {
          if (item.isAuthRequired) {
            return (<Route
              path={item.path}
              key={item.path}
              element={
                <PrivateRoute>
                  {item.Element}
                </PrivateRoute>
              }
            />)
          } else {
            return (
              <Route path={item.path}
                key={item.path}
                element={
                  <PublicRoute>
                    {item.Element}
                  </PublicRoute>}
              />)
          }
        })}
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeButton={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="colored"
      />
    </BrowserRouter>
  );
}

export default App;
