import { ReactNode, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setEmail, setUserViewData } from '../redux/slices/userViewSlice';

interface IPublicRoute {
  children: ReactNode;
}

const PublicRoute = ({ children }: IPublicRoute) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => (state.userAuth));
  const { userViewData } = useSelector((state: any) => state.userView);
  const { mail } = useParams();

  useEffect(() => {
    if (mail !== userViewData?.email) {
      dispatch(setUserViewData({ email: mail }));
    }
    if (mail) {
      dispatch(setEmail(mail));
    }
  }, [mail, userViewData])

  if (user?.email && !mail && user.name) {
    return <Navigate to={`/profile/${user.email}`} />
  } else if (user?.email && !mail && !user.name) {
    return <Navigate to={`/know-more`} />
  }

  return (
    <div className='flex flex-row justify-center w-full h-[calc(100dvh)] overflow-x-hidden'
      style={{ backgroundImage: `url(${userViewData?.bg})`, backgroundSize: 'auto', width: '100vw', height: '100vh' }}>
      <div className='px-4 py-4 overflow-auto w-full h-full pb-4'>
        {children}
      </div>
    </div>
  )
}

export default PublicRoute;