import { IModal } from '../components/interface';
import { IoMdClose } from "react-icons/io";

const Modal = ({ Open, setOpen, children, title }: IModal) => {
  return (
    <div className={`absolute z-50 top-0 right-0 left-0 bottom-0 flex flex-row justify-center items-center bg-black bg-opacity-85 h-svh w-screen ${Open ? 'block' : 'hidden'}`}>
      <div className="w-11/12 sm:w-4/5 md:w-4/6 lg:w-1/2 bg-white rounded-md relative">
        <div className='h-10 border-b-2 border-gray-200 shadow-md flex flex-row items-center justify-center px-4 font-bold text-gray-900'>
          <h5>{title}</h5>
          <IoMdClose size={20} onClick={() => setOpen(!Open)} className='absolute right-4 cursor-pointer' />
        </div>
        <div className='p-4'>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal