export const nonEditableFields = ['email'];

export const MoreFields = ['about', 'key_skills', 'other_skills', 'soft_skills', 'languages', 'achievements', 'work_experience', 'projects', 'education', 'certifications', 'important_links'];

export const MultiInputFormsFields = ['achievements', 'work_experience', 'projects', 'education', 'certifications'];

export const TitleDescMediaFields = ['achievements', 'certifications']

export const filterTheseFromTypeA = ['name', 'job_role', 'bg', 'email', 'phone_number', 'age', 'city', 'country', 'profile_pic', 'uid', 'important_links', 'achievements', 'work_experience', 'projects', 'education', 'certifications'];
export const filterTheseFromTypeB = ['achievements', 'work_experience', 'projects', 'education', 'certifications'];
export const filterTheseForLinkComponents = ['important_links'];

export const AdditionalFieldTitle: any = {
  'key_skills': 'Enter skills, separated by commas',
  'other_skills': 'Enter skills, separated by commas',
  'soft_skills': 'Enter skills, separated by commas',
  'languages': 'Enter languages, separated by commas',
  'important_links': 'Paste links to your other mediums below, seperated by commas'
}