import Card from "../core/Card";
import { SiAddthis } from "react-icons/si";
import Modal from "../core/Modal";
import { useState } from "react";
import { AdditionalFieldTitle, MoreFields } from "../utils/helper/constants";
import { capitaliseText } from "../utils/helper/genericFunctions";
import { TbTrashX } from "react-icons/tb";
import AddSectionForm from "./AddSectionForm";
import { useSelector } from "react-redux";

const AddSection = () => {
  const { user } = useSelector((state: any) => state.userAuth);
  const [Open, setOpen] = useState<boolean>(false);
  const [SelectedField, setSelectedField] = useState<string>('');

  const handleFieldSelection = (field: string) => {
    setSelectedField(field);
  }

  const onClose = () => {
    setOpen(!Open);
    setSelectedField('');
  }

  return (
    <>
      <Card bgColor="#E6E6E6" className="hover:shadow-xl cursor-pointer"
        ClickFn={onClose}>
        <div className="flex flex-row gap-2 items-center justify-center w-80 sm:w-[580px]">
          <SiAddthis size={23} color={"#6b7280"} />
          <p className="text-gray-500 p-0 font-medium text-sm">Add a section</p>
        </div>
      </Card >
      <Modal
        Open={Open}
        setOpen={setOpen}
        title={'Add a section'}
      >
        <div className="flex flex-col">
          {!SelectedField &&
            <div className="flex flex-row flex-wrap gap-2">
              {/* below filtering section from MoreFields that are already added*/}
              {MoreFields.filter((fltr) => user?.[fltr] === undefined).map((item: string, index: number) => {
                return (
                  <div key={index}
                    onClick={() => handleFieldSelection(item)}
                    className={`py-2 px-4 rounded-full cursor-pointer text-sm font-semibold bg-gray-200 text-black`}>
                    <p>{capitaliseText(item)}</p>
                  </div>
                )
              })}
            </div>
          }
          {SelectedField &&
            <>
              <div className="flex flex-row gap-1 mb-4 flex-wrap">
                <TbTrashX size={20} color="red" onClick={() => setSelectedField('')} className="cursor-pointer" />
                <h5 className="font-bold text-gray-900 text-nowrap">{capitaliseText(SelectedField)}</h5>
                {AdditionalFieldTitle[SelectedField] &&
                  <p className="text-sm text-gray-500 font-semibold mt-[2px] sm:text-nowrap">({AdditionalFieldTitle[SelectedField]})</p>
                }
              </div>
              <AddSectionForm SelectedField={SelectedField} setOpen={setOpen} />
            </>
          }
        </div>

      </Modal>
    </>
  )
}

export default AddSection;