import { useDispatch, useSelector } from "react-redux";
import { IAddSectionForm } from "./interface"
import { useEffect, useState } from "react";
import { IMultiFormComponent } from "../core/interface";
import { yupValidation } from "../utils/helper/yupValidation";
import { TitleDescMediaFields } from "../utils/helper/constants";
import imageCompression from 'browser-image-compression';
import MultiFormComponent from "../core/MultiFormComponent";
import { toast } from "react-toastify";
import { collection, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "../firebase-config";
import { setUserAuth } from "../redux/slices/userAuthSlice";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { capitaliseText, generateUniqueId, modifyUploadValuesForType } from "../utils/helper/genericFunctions";
import { FieldType } from "../utils/helper/FieldType";

const MultiInputForms = ({ SelectedField, setOpen }: IAddSectionForm) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.userAuth);
  const [LoaderState, setLoaderState] = useState<boolean>(false);
  const [LoadButton, setLoadButton] = useState<null | number>(null);
  const [InitialVal, setInitialVal] = useState<any>({});

  const compressImages = async (media: any) => {
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    return await Promise.all(media.map(async (file: any) => {
      return await imageCompression(file, options);
    }))
  }

  const callSubmitFn = async (values: any, media: any, btnIndex: number, resetForm?: any, setImagesContainer?: any) => {
    setLoaderState(true);
    const compressedFileArr = await compressImages(media);
    const storage = getStorage();
    const promises: any[] = [];
    let downloadURLs: any[] = [];
    let uploadTask: any;
    if (compressedFileArr.length > 0) {
      compressedFileArr.forEach((image) => {
        const metadata = {
          contentType: image.type,
        };
        image.name = generateUniqueId() + Math.random();
        uploadTask = uploadBytesResumable(ref(storage, 'media/' + image.name), image, metadata);
        promises.push(uploadTask);
      });

      Promise.all(promises).then(() => {
        promises.forEach((promise) => {
          promise.on('state_changed',
            (snapshot: any) => {
            },
            (error: any) => {
              console.log(error);
            },
            () => {
              const downloadURL = getDownloadURL(promise.snapshot.ref);
              downloadURL.then((url) => {
                downloadURLs.push(url);
                if (downloadURLs.length === promises.length) {
                  let uploadValue = modifyUploadValuesForType(values, SelectedField);
                  const q = query(collection(db, "users"), where("email", "==", user.email));
                  getDocs(q).then(async (querySnapshot) => {
                    if (querySnapshot.size === 1) {
                      const docRef = querySnapshot.docs[0].ref;
                      const existingData = querySnapshot.docs[0].data()[SelectedField];
                      const updatedData = existingData ? [...existingData, { ...uploadValue, media: downloadURLs }] : [{ ...uploadValue, media: downloadURLs }];
                      await updateDoc(docRef, {
                        [SelectedField]: updatedData
                      });
                      dispatch(setUserAuth({ ...user, [SelectedField]: updatedData }));

                      toast.success(`${SelectedField} added successfully!`);
                      setLoaderState(false);
                      setLoadButton(null);
                      downloadURLs = [];
                      if (resetForm && setImagesContainer) {
                        resetForm();
                        setImagesContainer([]);
                      }
                    }
                  });
                  if (btnIndex === 1) setOpen(false);
                }
              }
              );
            }
          );
        });
      });
    } else {
      let uploadValue = modifyUploadValuesForType(values, SelectedField);
      const q = query(collection(db, "users"), where("email", "==", user.email));
      getDocs(q).then(async (querySnapshot) => {
        if (querySnapshot.size === 1) {
          const docRef = querySnapshot.docs[0].ref;
          const existingData = querySnapshot.docs[0].data()[SelectedField];
          const updatedData = existingData ? [...existingData, { ...uploadValue, media: downloadURLs }] : [{ ...uploadValue, media: downloadURLs }];
          await updateDoc(docRef, {
            [SelectedField]: updatedData
          });
          dispatch(setUserAuth({ ...user, [SelectedField]: updatedData }));

          toast.success(`${capitaliseText(SelectedField)} added successfully!`);
          setLoaderState(false);
          setLoadButton(null);
          downloadURLs = [];
          if (resetForm && setImagesContainer) {
            resetForm();
            setImagesContainer([]);
          }
        }
      });
      if (btnIndex === 1) setOpen(false);
    }

  }


  const onSubmit = (values: any, media: any, btnIndex: number) => {
    // this function closes modal after submit
    callSubmitFn(values, media, btnIndex);
    setLoadButton(btnIndex)
  }

  const onSubmitNAddMore = (values: any, media: any, btnIndex: number, resetForm: any, setImagesContainer: any) => {
    // this function keeps modal open after submit
    callSubmitFn(values, media, btnIndex, resetForm, setImagesContainer);
    setLoadButton(btnIndex)
  }

  useEffect(() => {
    if (!!SelectedField && !!FieldType[SelectedField]) {
      let initialVal: any = {};
      Object.keys(FieldType[SelectedField]).map((item: any) => {
        initialVal[item] = '';
      })
      setInitialVal(initialVal);
    }
  }, [SelectedField])

  const FormComponentConfig: IMultiFormComponent = {
    fields: yupValidation[SelectedField],
    submitButtonText: "Submit & Close",
    submitButtonText2: "Submit & Add More",
    onSubmitFn: onSubmit,
    onSubmitFn2: onSubmitNAddMore,
    initialValues: InitialVal,
    LoadButton: LoadButton,
    LoaderState: LoaderState,
    SelectedField: SelectedField,
    fieldType: FieldType[SelectedField]
  }

  return (
    SelectedField ?
      <MultiFormComponent {...FormComponentConfig} />
      : <></>
  )
}

export default MultiInputForms