
interface IFeildType {
  [key: string]: any;
}

export const FieldType: IFeildType = {
  'about': 'textarea',
  'key_skills': 'textarea',
  'soft_skills': 'textarea',
  'other_skills': 'textarea',
  'languages': 'textarea',
  'important_links': 'textarea',
  'achievements': {
    title: '',
    description: 'textarea',
    media: 'file'
  },
  'certifications': {
    title: '',
    description: 'textarea',
    media: 'file'
  },
  'education': {
    degree: '',
    school: '',
    start_date: '',
    end_date: '',
    description: 'textarea',
    media: 'file'
  },
  'projects': {
    title: '',
    start_date: '',
    end_date: '',
    description: 'textarea',
    media: 'file'
  },
  'work_experience': {
    role: '',
    company: '',
    start_date: '',
    end_date: '',
    description: 'textarea',
    media: 'file'
  }
} 