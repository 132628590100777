import { IMultiFormComponent } from './interface';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { capitaliseText } from '../utils/helper/genericFunctions';
import CustomField from './CustomField';
import { RiImageAddLine } from "react-icons/ri";
import Button from './Button';
import { useState } from 'react';
import { FaWindowClose } from "react-icons/fa";


const MultiFormComponent = ({ fields,
  submitButtonText,
  onSubmitFn,
  onSubmitFn2,
  initialValues,
  fieldType,
  LoaderState,
  SelectedField = '',
  submitButtonText2,
  LoadButton
}: IMultiFormComponent) => {
  const schema = Yup.object().shape(fields);
  const [ImagesContainer, setImagesContainer] = useState<File[]>([]);

  return (
    <div>
      <Formik
        enableReinitialize={true}
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={() => { }}
      >
        {({ isValid, dirty, errors, touched, values, resetForm }: any) => {
          return (
            <Form className="w-full h-full">
              <div className='max-h-[70vh] overflow-auto pr-2 mb-4'>
                {Object.keys(initialValues).map((item: any, index: any) => {
                  let placeholder = capitaliseText(item) || '';
                  return (
                    <div className="mb-4" key={index}>
                      {['title', 'degree', 'role'].includes(item) &&
                        <>
                          <CustomField
                            name={item}
                            placeholder={placeholder}
                            isError={errors[item]}
                            fieldType={fieldType?.[item]}
                          />
                          {errors[item] &&
                            <p className="text-orange-700 text-sm mt-2 font-semibold">{errors[item]}</p>
                          }
                        </>
                      }

                      {['company', 'school'].includes(item) &&
                        <>
                          <CustomField
                            name={item}
                            placeholder={placeholder}
                            isError={errors[item]}
                            fieldType={fieldType?.[item]}
                          />
                          {errors[item] &&
                            <p className="text-orange-700 text-sm mt-2 font-semibold">{errors[item]}</p>
                          }
                        </>
                      }
                    </div>
                  )
                }
                )}
                <div className='grid grid-cols-2 gap-x-4'>
                  {Object.keys(initialValues).filter(f => f.includes('start_date')).map((item: any, index: any) => {
                    let placeholder = capitaliseText(item) || '';
                    return (
                      ['start_date'].includes(item) &&
                      <div key={index}>
                        <CustomField
                          name={item}
                          placeholder={placeholder}
                          isError={errors[item]}
                          fieldType={fieldType?.[item]}
                        />
                        {errors[item] &&
                          <p className="text-orange-700 text-sm mt-2 font-semibold">{errors[item]}</p>
                        }
                      </div>
                    )
                  }
                  )}

                  {Object.keys(initialValues).filter(f => f.includes('end_date')).map((item: any, index: any) => {
                    let placeholder = capitaliseText(item) || '';
                    return (
                      <div key={index}>
                        <CustomField
                          name={item}
                          placeholder={placeholder}
                          isError={errors[item]}
                          fieldType={fieldType?.[item]}
                        />
                        {errors[item] &&
                          <p className="text-orange-700 text-sm mt-2 font-semibold">{errors[item]}</p>
                        }
                      </div>
                    )
                  }
                  )}
                </div>
                {Object.keys(initialValues).map((item: any, index: any) => {
                  let placeholder = capitaliseText(item) || '';
                  return (
                    <div className="mb-4" key={index}>
                      {['description'].includes(item) &&
                        <CustomField
                          name={item}
                          placeholder={placeholder}
                          isError={errors[item]}
                          fieldType={fieldType?.[item]}
                        />}
                      {
                        ['media'].includes(item) &&
                        // take mutple files upload
                        <div className="relative flex flex-row items-center">
                          <label htmlFor={SelectedField}
                            className='cursor-pointer font-medium text-sm text-gray-400 flex flex-row items-center justify-center gap-x-2 rounded-md p-4  border-[1px] bg-white shadow-md'>
                            <RiImageAddLine size={20} />
                            Add Photos
                            <Field
                              type="file"
                              name={item}
                              accept='image/*'
                              multiple
                              onChange={(event: any) => {
                                setImagesContainer((prev) => {
                                  return [...prev, ...event.target.files]
                                })
                              }}
                              className='hidden'
                              id={SelectedField}
                            />
                          </label>
                        </div>
                      }
                    </div>)
                }
                )}
                <div className='flex flex-row items-center gap-x-2'>
                  {ImagesContainer && ImagesContainer.length > 0 && ImagesContainer.map((file, index) => {
                    return (
                      <div className='relative' key={index}><img src={URL.createObjectURL(file)} alt={file.name} className='w-20 border-[1px] shadow-sm' /><button type='button' onClick={() => {
                        setImagesContainer((prev) => {
                          return prev.filter((item) => item.name !== file.name);
                        });
                      }}><FaWindowClose className='absolute -top-1 -right-1 text-red-500' /></button>
                      </div>
                    )
                  }
                  )}
                </div>
              </div>
              <div className='flex flex-row justify-between items-center gap-x-4'>
                <Button
                  disabled={!(isValid && dirty) || LoaderState}
                  variant="secondary"
                  type="submit"
                  LoaderState={LoadButton === 0}
                  onClick={(e) => {
                    e.preventDefault();
                    onSubmitFn2(values, ImagesContainer, 0, resetForm, setImagesContainer);
                  }}
                >
                  {submitButtonText2}
                </Button>
                <Button
                  disabled={!(isValid && dirty) || LoaderState}
                  variant="primary"
                  type="submit"
                  LoaderState={LoadButton === 1}
                  onClick={(e) => {
                    e.preventDefault();
                    onSubmitFn(values, ImagesContainer, 1);
                  }}
                >
                  {submitButtonText}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div >
  )
};

export default MultiFormComponent;