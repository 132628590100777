import FormComponent from '../../core/FormComponent';
import * as Yup from 'yup';
import { IFormComponent } from '../../core/interface';
import { collection, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from '../../firebase-config';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setUserAuth } from '../../redux/slices/userAuthSlice';
import { Navigate } from 'react-router-dom';
import { yupValidation } from '../../utils/helper/yupValidation';


const OnSignupForm = () => {
  const { user } = useSelector((state: any) => state.userAuth);
  const dispatch = useDispatch();

  const onSubmit = async (values: any) => {
    try {
      const q = query(collection(db, "users"), where("email", "==", user.email));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size === 1) {
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, { ...values });
        dispatch(setUserAuth({ ...user, ...values }));
      } else {
        toast.error(`Please try again!`);
      }
    } catch (error) {
      toast.error(`Please try again!`);
    }
  }

  const FormComponentConfig: IFormComponent = {
    fields: {
      name: yupValidation['name'],
      phone_number: yupValidation['phone_number'],
      city: yupValidation['city'],
      country: yupValidation['country'],
      job_role: yupValidation['job_role'],
      age: yupValidation['age']
    },
    submitButtonText: "Submit",
    onSubmitFn: onSubmit,
    initialValues: {
      name: '',
      phone_number: '',
      city: '',
      country: '',
      job_role: '',
      age: ''
    }
  }

  if (user?.name) {
    return <Navigate to={`/profile/${user.email}`} />
  }

  return (
    <div className='min-h-screen flex flex-col items-center justify-center gap-y-5'>
      <div className='flex flex-col items-start px-4 w-96 md:w-[500px] gap-y-2'>
        <p className='text-2xl font-bold text-left leading-7'>We want to know a little<br />more about you!</p>
        <p className='text-base font-normal text-gray-600 text-left'>let's get some basic details for your portfolio</p>
      </div>
      <div className='w-96 md:w-[500px] px-4'>
        <FormComponent {...FormComponentConfig} />
      </div>
    </div>
  )
}

export default OnSignupForm;