import { useEffect, useState } from 'react'
import { IUser } from './interface'
import Card from '../core/Card';
import Editable from '../core/Editable';
import { GetInfoTypeKey, capitaliseText } from '../utils/helper/genericFunctions';
import { filterTheseFromTypeA } from '../utils/helper/constants';
import { icons } from '../utils/helper/icons';

const UserDetailsTypeA = ({ user, isEditable = true }: IUser) => {
  const [UserFilter, setUserFilter] = useState<any[]>([]);
  useEffect(() => {
    const data: any = [];
    Object.entries(user).map((item: any[]) => {
      if (!filterTheseFromTypeA.includes(item[0]))
        data.push({
          title: item[0], data: item[1]
        })
    })
    setUserFilter(data);
  }, [user])

  return (
    <div>
      {UserFilter.length > 0 ?
        (UserFilter.map((item, index) => {
          return (
            <Card bgColor="#ffffff" key={index}>
              <div className='flex flex-col w-80 sm:w-[580px]'>
                <div className='flex flex-row items-center gap-2 mb-2'>
                  {icons?.[item.title] &&
                    <div className='rounded-full p-2 bg-primary-100'>
                      {icons?.[item.title]}
                    </div>
                  }
                  <p className='font-bold text-sm'>{capitaliseText(item.title)}</p>
                </div>
                <div className='flex flex-col text-sm mt-2'>
                  <Editable
                    isEditable={isEditable}
                    className="text-sm"
                    text={item.data}
                    infoType={GetInfoTypeKey(user, item.data)}
                  />
                </div>
              </div>
            </Card>
          )
        })) : null
      }
    </div>
  )
}

export default UserDetailsTypeA